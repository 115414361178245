<template>
    <div>
        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'crm-contracttemplate-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista Contratti Template
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
            <b-card>
                <b-card-header class="pt-0">
                    <b-card-title>
                    {{ campiform.nome }}
                    </b-card-title>
                    <b-button
                        variant="primary"
                        class="btn-icon rounded-circle"
                        id="`sidebar-azioni`"
                        @click="sidebarActionShow(campiform.id, campiform.nome)"
                    >
                        <feather-icon icon="ArrowRightIcon" />
                    </b-button>
                </b-card-header>

                <b-card-body class="px-0 py-0">
                    <b-tabs pills>
                        <b-tab>
                            <template #title>
                                <feather-icon
                                icon="FileTextIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                                />
                                <span class="d-none d-sm-inline">Opzioni Contratto</span>
                            </template>
                            <b-card bg-variant="Default">
                                <b-row>
                                  <b-col lg="12">
                                      <showdata field_name="Descrizione del template" :field_value="campiform.descrizione" />
                                  </b-col>
                                  <b-col lg="6">
                                      <showdata field_name="Licenza" :field_value="campiform.nome_licenza" />
                                  </b-col>
                                  <b-col lg="6">
                                      <showdata field_name="Codice prodotto Stripe" :field_value="campiform.id_prod_stripe" />
                                  </b-col>
                                
                                </b-row>

                            </b-card>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <feather-icon
                                icon="FileTextIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                                />
                                <span class="d-none d-sm-inline">Contenuto Modulo d'Ordine</span>
                            </template>

                            <b-card bg-variant="Default">
                                <div class="ql-container ql-snow">
                                    <div class="ql-editor">
                                        <div v-html="campiform.modulo_ordine"></div>
                                    </div>
                                </div>
                                
                            </b-card>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <feather-icon
                                icon="FileTextIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                                />
                                <span class="d-none d-sm-inline">Contenuto Termini e Condizioni</span>
                            </template>

                            <b-card bg-variant="Default">
                                <div class="ql-container ql-snow mb-3">
                                    <div class="ql-editor">
                                        <div v-html="campiform.termini_e_condizioni"></div>
                                    </div>
                                </div>

                                <div class="ql-container ql-snow">
                                    <div class="ql-editor">
                                        <div v-html="campiform.clausole_vessatorie"></div>
                                    </div>
                                </div>
                                
                            </b-card>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <feather-icon
                                icon="FileTextIcon"
                                size="16"
                                class="mr-0 mr-sm-50"
                                />
                                <span class="d-none d-sm-inline">Contenuto Privacy Policy</span>
                            </template>

                            <b-card bg-variant="Default">
                                <div class="ql-container ql-snow">
                                    <div class="ql-editor">
                                        <div v-html="campiform.privacy_policy"></div>
                                    </div>
                                </div>
                                
                            </b-card>
                        </b-tab>
                    </b-tabs>

                </b-card-body>
            </b-card>
        </div>

        <b-sidebar
        ref="sidebar_azioni"
        id="sidebar-right"
        bg-variant="white"
        :title="infoSideBar.name"
        right
        backdrop
        shadow
        >
        <div>
            <div class="py-0 px-2">
                <div class="divider">
                    <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
                </div>
                
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning" block
                    class="mb-1"
                    @click="$router.push({ name: 'tools-contracttemplate-edit', params: { id_riga: infoSideBar.id }})"
                >
                    <feather-icon icon="Edit2Icon" /> Modifica
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-warning" block
                    class="mb-1"
                    @click="$router.push({ name: 'tools-contracttemplate-duplic', params: { id_riga: infoSideBar.id }})"
                >
                    <feather-icon icon="CopyIcon" /> Duplica
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger" block
                    class="mb-1"
                    @click="cancellaRiga(infoSideBar.id)"
                >
                    <feather-icon icon="Trash2Icon" /> Cancella
                </b-button>
            </div>

        </div>
        </b-sidebar>
          
    </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, BBadge } from 'bootstrap-vue'
import showdata from '@core/components/showdata/showdata.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    BBadge,

    showdata,   
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      campiform: {
        nome: '',
        id_prod_stripe: '',
        nome_licenza: '', 
        descrizione: '',
        modulo_ordine: '',
        termini_e_condizioni: '',
        clausole_vessatorie: '',
        privacy_policy: ''
      },
      options_licenze_stripe: [ ],
      Caricato: false,
      errore_caricamento: false,
      infoSideBar: {
        id: '',
        nome: '',
      },
    }
  },
  created() {
    const categoriesPromise = this.$http.get('api/crm/stripe/product/selectlist')  
    const infoContracttemplatePromise = this.$http.get('api/crm/contracttemplate/card/'+router.currentRoute.params.id_riga)

    Promise.all([categoriesPromise, infoContracttemplatePromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista categorie
        this.options_categorie = response[0].data.reply

        if(response[1].data.statusCode===200){
            //form dati email template
            this.campiform = response[1].data.reply.data

            //trova nome della licenza
            const product = this.options_categorie.find(p => p.value === this.campiform.id_prod_stripe);
            this.campiform.nome_licenza = product ? product.text : "Licenza non trovata";

            //fine
            this.Caricato = true;
            this.errore_caricamento = false;
        } else {
            this.Caricato = false;
            this.errore_caricamento = true;
        }

      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
        
    })
  },
  methods: {
    sidebarActionShow(id, nome) {
      this.infoSideBar = { id: id, nome: nome };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    cancellaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del contratto template?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/contracttemplate/delete/'+id_riga)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems();
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
